import { Injectable } from '@angular/core';
import { DateAdapter } from './date-adapter';
import { DateStruct } from '../date';
import { isInteger } from '../../util';

@Injectable()
export class DateNativeAdapter extends DateAdapter<Date> {

  /** Converts a native `Date` to a `DateStruct`. */
  fromModel(date: Date | null): DateStruct | null {
    return (date instanceof Date && !isNaN(date.getTime())) ? this._fromNativeDate(date) : null
  }

  /** Converts a `DateStruct` to a native `Date`. */
  toModel(date: DateStruct | null): Date | null {
    return date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day) ? this._toNativeDate(date) : null
  }

  protected _fromNativeDate(date: Date): DateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  protected _toNativeDate(date: DateStruct): Date {
    const jsDate = new Date(date.year, date.month - 1, date.day, 12);
    // avoid 30 -> 1930 conversion
    jsDate.setFullYear(date.year);
    return jsDate;
  }
}
