import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Datepicker } from './datepicker.directive';
import { DatepickerInput } from './datepicker.input';
import { DatepickerConfig } from './datepicker-config';

export * from './adapters';
export { DatepickerConfig } from './datepicker-config';
export { DatePeriod, Calendar, CalendarGregorian } from './calendarType';

@NgModule({
  exports: [Datepicker, DatepickerInput],
  imports: [CommonModule, FormsModule],
  providers: [DatepickerConfig],
  declarations: [Datepicker, DatepickerInput],
})
export class DatepickerModule { }